<template>
  <div class="w-screen h-screen flex">
    <div class="w-full md:w-1/2 bg-gray-100">
      <div class="container flex flex-col w-2/3 mt-20">
        <router-link to="/">
          <img
            src="@/_assets/images/hive_logo.svg"
            alt=""
            class="w-48 h-16 object-contain mb-12"
          />
        </router-link>

        <router-view></router-view>
      </div>
    </div>
    <div
      class="hidden md:w-1/2 bg-darkblue-500 text-white md:flex justify-center items-center"
    >
      <div class="fixed">
        <img src="@/_assets/images/pay_img.png" class="" />
      </div>
    </div>
  </div>
</template>
<script>
//import auth from "../_utils/auth";

export default {
  name: "Legal"
};
</script>
<style lang="postcss" scoped></style>
